import { Atom, Cmp, Ctrl, Mod, api } from ":mods";
import { _token_actions, actions } from "../store";
import { getTokenDetails } from "../apis";
import { ROUTES } from "../const";
// import { BASE_ROUTES } from "../const";

// TODO: check token only once across routes
// TODO: disable token checks on /auth but not /auth/login
// TODO: store non critical indicators in local storage to know this
export const checkUserToken: Atom.Route.model.AGuardStep = {
  runOn: ["app_init", "first_load"],
  loaderMsg: "checking token...",
  async checker({ state, base, routeMatch, routeMatchBase, location }) {
    // console.log("checkToken guard loaded :: with state :: ", state, " :: and base :: ", base, " :: ");
    const is_auth_route = routeMatchBase(Ctrl.Routes.CONST.BASE_ROUTES.auth);
    if (is_auth_route) {
      // const is_auth_login_route = routeMatch(ROUTES.auth.login);
      // if (!is_auth_login_route) {
      // }
      return undefined;
    }

    return getTokenDetails()
      .then((token_details) => {
        // setDetailsWithToken(user_data);
        // console.warn("token details :: ", token_details);
        // console.log("checkToken guard loaded :: with state :: ", state, " :: and base :: ", base, " :: ");
        _token_actions.set(token_details);
        // console.log("user data exist is valid :: ", user_data);
        return Promise.resolve(undefined);
      })
      .catch((e) => {
        const prev_location_is_login = routeMatch(
          { base: Ctrl.Routes.CONST.BASE_ROUTES.auth, path: ROUTES.auth.login },
          location.previous?.pathname
        );
        //? check if token encoded exists
        let error = "unauthenticated user, logging out!"; //? default error, not neccesary occurance
        if (prev_location_is_login) {
          const token = actions.getTokenEncoded();
          //? user logged in but cookies are disabled, prepare one time session
          if (token) {
            api.setSessionToken(token.access);
            Cmp.Alert.actions.pushToast({
              type: "info",
              message:
                "One Time Session is activated due to disabled cookies. \nYoull stay connected so long you don't reload the page or exit browser!.",
            });
            return Promise.resolve(undefined);
            // return Promise.resolve({ error: "one time session" });
          }
          error = "an error occured while trying to fetch user details, please contact support!";
          // TODO: pass controller actions or subset of it to guards as well
          Cmp.Alert.actions.pushToast({
            type: "error",
            message: error,
          });
        }
        // console.warn("error :: ", error);
        _token_actions.rm();
        return Promise.resolve({
          error,
          navigate: {
            // path: routeMatch(BASE_ROUTES.auth) ? undefined : Mod.Account.CONST.ROUTES.login,
            // base: BASE_ROUTES.auth,
          },
        });
      });
  },
};
